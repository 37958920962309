import React, { useReducer } from 'react';

export const OrderContext = React.createContext();
export const OrderDispatch = React.createContext();

let localStorage = typeof window !== `undefined` ? window.localStorage : null;

const orderReducer = (state, action) => {
  let orders = state;
  switch (action.type) {
    case 'add':
      orders = [...state, action.value];
      break;
    case 'delete':
      orders = state.filter(orderline => orderline.id !== action.value);
      break;
    default:
      orders = state;
      break;
  }
  if (localStorage) {
    localStorage.setItem('orders', JSON.stringify(orders));
  }
  return orders;
};

// To be loaded from LocalStorage
const existingOrders = localStorage && localStorage.getItem('orders');
const initialOrderState = existingOrders ? JSON.parse(existingOrders) : [];

export default props => {
  const [orderState, orderDispatch] = useReducer(
    orderReducer,
    initialOrderState
  );

  const { children } = props;
  return (
    <OrderContext.Provider value={orderState}>
      <OrderDispatch.Provider value={orderDispatch}>
        {children}
      </OrderDispatch.Provider>
    </OrderContext.Provider>
  );
};

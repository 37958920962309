// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("/Users/b/Projects/bananapark-website/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-custom-phone-case-js": () => import("/Users/b/Projects/bananapark-website/src/pages/custom-phone-case.js" /* webpackChunkName: "component---src-pages-custom-phone-case-js" */),
  "component---src-pages-index-js": () => import("/Users/b/Projects/bananapark-website/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-order-complete-js": () => import("/Users/b/Projects/bananapark-website/src/pages/order-complete.js" /* webpackChunkName: "component---src-pages-order-complete-js" */),
  "component---src-pages-order-error-js": () => import("/Users/b/Projects/bananapark-website/src/pages/order-error.js" /* webpackChunkName: "component---src-pages-order-error-js" */),
  "component---src-pages-order-js": () => import("/Users/b/Projects/bananapark-website/src/pages/order.js" /* webpackChunkName: "component---src-pages-order-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/Users/b/Projects/bananapark-website/.cache/data.json")

